import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './style.css'
import './views/home.css'
import NotFound from './views/not-found'
import PrivacyPolicy from './views/privacy-policy'
import Documentation from './views/documentation'
import Home from './views/home'
import TermsOfService from './views/terms-of-service'
import Contact from './views/contact'
import Commands from './views/commands'
import Status from './views/status'
import Staff from './views/staff'
import LendMeATenor from './views/lend-me-a-tenor'
import TheWeddingSinger from './views/theweddingsinger'
import AroundTheWorld from './views/around-the-world'

const App = () => {
  return (
    <Router>
      {/* <div className="home-site-notification">
        <span className='home-notification-text-bold'>
          <span>
            Website Upgrades Coming Soon!
          </span>
        </span>
        <br></br>
        <span className="home-notification-text">
          <span>
            Attention all Daiki Development Customers, Daiki Development Website will be undergoing major updates that will change the structure of our website.
            <br></br>
            This may take down Daiki Development Websites for a short amount of time. Please contact us if you have any questions or concerns!
            Thank you!
          </span>
          <br></br>
        </span>
        {/* <a
          href="https://discord.com/invite/b6EZgfTMmz"
          target="_blank"
          rel="noreferrer noopener"
          className="home-notification-button button"
        >
          View More In Discord
        </a>
      </div> */}
      <Switch>
        <Route component={PrivacyPolicy} exact path="/privacy" />
        <Route component={Documentation} exact path="/docs" />
        <Route component={Home} exact path="/" />
        <Route component={TermsOfService} exact path="/tos" />
        <Route component={Contact} exact path="/contact" />
        <Route component={Commands} exact path="/commands" />
        <Route component={LendMeATenor} exact path="/lendmeatenor" />
        <Route component={TheWeddingSinger} exact path="/theweddingsinger" />
        <Route component={AroundTheWorld} exact path="/aroundtheworld" />
        {/* <Route component={Status} exact path={"/status"} /> */}
        {/* <Route component={Staff} exact path="/staff" /> */}
        {/*AED CLASS <Route path="/requests" component={() => {
          window.location.href = "https://forms.gle/3R5XF9RYWy2Wnx3Y7"
        }} /> */}
        {/* <Route path='/status' component={() => {
          window.location.href = 'https://status.daiki-bot.xyz';
          return null;
        }} /> */}
        <Route path='/staff' component={() => {
          window.location.href = 'https://admin.dashboard.daiki-bot.xyz';
          return null;
        }} />
        <Route path='/invite' component={() => {
          window.location.href = 'https://top.gg/bot/839287174482362438/invite/';
          return null;
        }} />
        <Route path='/support' component={() => {
          window.location.href = 'https://discord.com/invite/nWUB8RNB72';
          return null;
        }} />
        <Route path='/top/vote' component={() => {
          window.location.href = 'https://top.gg/bot/839287174482362438/vote'
        }} />
        <Route path='/top/main' component={() => {
          window.location.href = 'https://top.gg/bot/839287174482362438'
        }} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
